.services-paragraph {
    font-weight: 400;
}

.customer-timeline-title {
    font-weight: 400;
    text-decoration: underline;
    padding-bottom: 16px;
}

.services-h2 {
    padding-top: 16px;
}