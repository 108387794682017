.about-us-image {
    padding-bottom: 16px;
}

.services {
    color: #4f4a4a;

    .services-list {
        margin-top: 15px;

        .services-list-item {
            text-align: left !important;
            font-size: 15px;
        }
    }

    .education-summary {
        color: black;
    }
}

.contact-form-link {
    color: rgb(251, 198, 88);
}

.contact-form-link:hover {
    color: rgb(173, 124, 23);
}

.white-text {
    color: lightgray;
}

.services-card-footer {
    padding-top: 0px !important;
}

.hours-list {
    list-style-type: none;
}


